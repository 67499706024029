import React from 'react'
import * as Styled from '../styles/contact.style.jsx'
import Helmet from 'react-helmet'

import LoopStatic from '../components/LoopStatic'
import Footer from '../components/Footer'

import facebook from '../images/icons/facebook.svg'
import youtube from '../images/icons/youtube.svg'
import spotify from '../images/icons/spotify.svg'
import instagram from '../images/icons/instagram.svg'

const Contact = () => {
  return (
    <>
      <Styled.Contact>
        <Helmet title="Contact | Jacques" htmlAttributes={{lang: 'fr'}} />
        <LoopStatic size="250px" />
        <div className="socialsContainer">
          <a
            href="https://www.instagram.com/jacques_vortex/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC05Om8nHItHP3xi4N8g1eMQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
          <a
            href="https://www.facebook.com/jacquesvortex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://open.spotify.com/artist/55i4AnS7E58y41UwE0vvQh?si=UtPT2vzjQg67-ftrgRfbQA&nd=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={spotify} alt="spotify" />
          </a>
        </div>
        <div className="emailsContainers">
          <b>TOUR</b>
          <a
            href="mailto:alex@supermonamour.com"
            className="email"
            target="_blank"
            rel="noopener noreferrer"
          >
            alex@supermonamour.com
          </a>
          <br />
          <b>MGMT</b>
          <a
            href="mailto:etiennepiketty@gmail.com"
            className="email"
            target="_blank"
            rel="noopener noreferrer"
          >
            etiennepiketty@gmail.com
          </a>
          <a
            href="mailto:fanny@hotline-mgmt.com"
            className="email"
            target="_blank"
            rel="noopener noreferrer"
          >
            fanny@hotline-mgmt.com
          </a>
          <br />
          <b>LABEL</b>
          <a
            href="mailto:etiennepiketty@gmail.com"
            className="email"
            target="_blank"
            rel="noopener noreferrer"
          >
            etiennepiketty@gmail.com
          </a>
        </div>
      </Styled.Contact>
      <Footer />
    </>
  )
}

export default Contact
