import theme from './theme'
import styled from 'styled-components'

export const Contact = styled.main`
  max-width: ${theme.maxWidth};
  margin: 4rem auto;
  padding: 0 ${theme.paddingAround};
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 62vh;
  .socialsContainer {
    display: flex;
    gap: 1rem;
    img {
      width: 40px;
      height: 40px;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .emailsContainers {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
  }
`
